/**
 * 概念术语相关常量
 *
 *  2021-04-22  wuzhipeng
 */

module.exports = {
  /**语言概念域*/
  PUB_LANG: 'pub_lang',
  /**语言概念域-中文*/
  PUB_LANG_CH:100001,
  /**语言概念域-英文*/
  PUB_LANG_EN:100002,
  /**名称类型概念域*/
  PUB_NAME_TYPE: 'pub_name_type',
  /**名称类型概念域-英文名INN*/
  PUB_NAME_TYPE_EN:108633,
  /**名称类型概念域-通用名*/
  PUB_NAME_TYPE_GENERIC:108631,
  /**名称类型概念域-商品名*/
  PUB_NAME_TYPE_TRADE:108634,
  /**物质类型概念域*/
  DKB_SUBSTANCE_TYPE:'dkb_substance_type',
  /**物质属性概念域*/
  DKB_SUBSTANCE_PROPS:'dkb_substance_props',
  /**!!数据状态概念域*/
  PUB_DATA_STATUS: 'pub_data_status',
  /**药物分类概念域 (西药、中成药、草药等)*/
  DKB_DRUG_CLASSIFY:'dkb_drug_classify',
  /**药物成分类型概念域（活性成分、辅料等）*/
  DKB_DRUG_INGREDIENT_TYPE:'dkb_drug_ingredient_type',
  /**药品国产进口分类(国产、进口等)*/
  DKB_DRUG_IMPORT_CLASS:'dkb_drug_import_class',
  /**药品国产进口分类--国产*/
  DKB_DRUG_IMPORT_CLASS_DOMESTIC:108601,
  /**药品国产进口分类--进口*/
  DKB_DRUG_IMPORT_CLASS_IMPORT:108602,
  /**算数运算符概念域（大于、小于等）*/
  PUB_MATH_OPERATOR:'pub_math_operator',
  /**质量单位概念域（千克、克、毫克等）*/
  PUB_MASS_UNIT:'pub_mass_unit',
  /**体积单位概念域*/
  PUB_VOLUME_UNIT:'pub_volume_unit',
  /**国际单位、活性单位概念域*/
  PUB_ACTIVE_UNIT:'pub_active_unit',
  /**有效期时间单位概念域（年、月等）*/
  PUB_TIME_UNIT_EXPIRATION:'pub_time_unit_expiration',
  /**有效期时间单位概念域---年*/
  PUB_TIME_UNIT_EXPIRATION_YEAR:103208,
  /**有效期时间单位概念域---月*/
  PUB_TIME_UNIT_EXPIRATION_MONTH:103207,
  /**规格包装单位概念域（片、粒、袋、支、瓶、盒等）*/
  PUB_SPEC_PKG_UNIT:'pub_spec_pkg_unit',
  /**规格包装单位概念域-盒*/
  PUB_SPEC_PKG_UNIT_BOX:103719,
  /**规格包装单位概念域-板*/
  PUB_SPEC_PKG_UNIT_BOARD:103720,
  /**药品组合包装等级（一级、二级、全部等）*/
  DKB_DRUG_COMBO_LEVEL:'dkb_drug_combo_level',
  /**药物制剂剂型概念域*/
  DKB_PP_FORM:'dkb_pp_form',
  /**中药类别规格概念域*/
  DKB_TCM_CLASS_SPEC:'dkb_tcm_class_spec',
  /**中药炮制方法概念域*/
  DKB_TCM_PROC_METHOD:'dkb_tcm_proc_method',
  /**计量单位概念域*/
  PUB_MEASURE_UNIT:'pub_measure_unit',
  /**用药途径概念域*/
  DKB_ADMINISTRATION_ROUTE:'dkb_administration_route',
  /**用药部位概念域*/
  DKB_APPLICATION_SITE:'dkb_application_site',
  /**企业类型概念域*/
  PUB_MANUFACTURER_TYPE: 'pub_manufacturer_type',
}
