import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 下载药品元数据模板
export function download(recordId) {
  return request({
    url: '/dm/drug/pre/download/' + praseStrEmpty(recordId),
    method: 'get',
    resopnseReturn: true,
    responseType: 'blob'
  })
}

// 预处理提交
export function submit(recordId) {
  return request({
    url: '/dm/drug/pre/submit/' + praseStrEmpty(recordId),
    method: 'put'
  })
}

// 根据上传记录ID获取该批次的类型集合
export function type(recordId) {
  return request({
    url: '/dm/drug/pre/type/' + praseStrEmpty(recordId),
    method: 'get'
  })
}

// 预处理相关Excel上传
export function upload(data) {
  return request({
    url: '/dm/drug/pre/upload',
    method: 'post',
    data: data
  })
}

// 上传预处理列表查询
export function list(data) {
  return request({
    url: '/dm/drug/pre/list',
    method: 'get',
    params: data
  })
}

// 根据记录ID获取记录头信息
export function head(recordId) {
  return request({
    url: '/dm/common/head/' + praseStrEmpty(recordId),
    method: 'get'
  })
}


// 驳回上传
export function reject(recordId) {
  return request({
    url: '/dm/drug/pre/reject/' + praseStrEmpty(recordId),
    method: 'put'
  })
}
// 导出
export function mappingDownload(recordId) {
  return request({
    url: '/dm/drug/match/download/' + praseStrEmpty(recordId),
    method: 'get',
    resopnseReturn: true,
    responseType: 'blob'
  })
}
